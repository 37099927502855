import {
  addStudentCourse,
  deleteStudentCourse,
  getAll,
} from "@/services/studentCoursesService";
import { get } from "lodash";

const state = {
  studentsCourses: [],
};

const getters = {
  getStudentsCourses: (state) => state.studentsCourses,
};

const mutations = {
  setStudentsCourses(state, payload) {
    state.studentsCourses = payload;
  },
};

const actions = {
  async setStudentsCourses({ commit }, id = null) {
    const { data } = await getAll({ id });
    commit("setStudentsCourses", data);
  },

  async deleteStudentCourse({ dispatch }, payload) {
    await deleteStudentCourse(payload);
    dispatch("setStudentsCourses", get(payload, "studentId"));
  },

  async addStudentCourse({ dispatch }, payload) {
    await addStudentCourse(payload);
    dispatch("setStudentsCourses", get(payload, "studentId"));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
