import Vue from "vue";
import Router from "vue-router";
import multiguard from "vue-router-multiguard";
import authenticate from "../guards/authenticate";
import hasCrudPermission from "../guards/hasCrudPermission";
import resetPassword from "../guards/resetPassword";
import unAuthenticated from "../guards/unAuthenticated";
import accessiblePages from "../resolvers/accessiblePages";
import courseItems from "../resolvers/courseItems";
import courseList, {
  courseItemQuestions,
  scormData,
} from "../resolvers/courseList";
import customerCourseList from "../resolvers/customerCourseList";
import {
  customerStudents,
  customerStudentsForm,
  customersData,
} from "../resolvers/customerList";
import eventList from "../resolvers/eventList";
import modelEdit from "../resolvers/modelEdit";
import modelList from "../resolvers/modelList";
import signUpList from "../resolvers/signUpList";
import studentCourseList from "../resolvers/studentCourseList";
import userTypes from "../resolvers/userTypes";
import videoInputs from "../resolvers/videoInputs";
import videoList from "../resolvers/videoList";
import { MODEL_ACTION } from "../shared/constants";
import store from "../store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/auth",
      component: () => import("@/views/auth/Index"),
      redirect: "/auth/sign-in",
      children: [
        {
          path: "sign-in",
          name: "sign-in",
          beforeEnter: unAuthenticated,
          component: () => import("@/views/auth/SignIn"),
        },
        {
          path: "forgot-password",
          name: "forgot-password",
          beforeEnter: unAuthenticated,
          component: () => import("@/views/auth/ForgotPassword"),
        },
        {
          path: "reset-password",
          name: "reset-password",
          beforeEnter: multiguard([unAuthenticated, resetPassword]),
          component: () => import("@/views/auth/ResetPassword"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/views/app/Index"),
      beforeEnter: multiguard([authenticate, accessiblePages]),
      redirect: "/app/dashboard",
      children: [
        {
          path: "/app/models/:model",
          component: () => import("@/views/app/models/Index"),
          redirect: "/",
          children: [
            {
              path: "/",
              component: () => import("@/views/app/models/List"),
              beforeEnter: modelList,
            },
            {
              path: "create",
              component: () => import("@/views/app/models/Form"),
              beforeEnter: hasCrudPermission,
              props: true,
              meta: { kind: MODEL_ACTION.CREATE },
            },
            {
              path: ":id/edit",
              component: () => import("@/views/app/models/Form"),
              beforeEnter: multiguard([modelEdit, hasCrudPermission]),
              props: true,
              meta: { kind: MODEL_ACTION.EDIT },
            },
          ],
        },
        {
          path: "/app/page",
          component: () => import("@/views/app/pages/Index"),
          redirect: "/",
          children: [
            {
              path: "/",
              component: () => import("@/views/app/pages/List"),
            },
            {
              path: "create",
              component: () => import("@/views/app/pages/Form"),
              beforeEnter: userTypes,
              props: true,
              meta: { kind: MODEL_ACTION.CREATE },
            },
            {
              path: ":id/edit",
              component: () => import("@/views/app/pages/Form"),
              beforeEnter: userTypes,
              props: true,
              meta: { kind: MODEL_ACTION.EDIT },
            },
          ],
        },
        {
          path: "/app/event",
          component: () => import("@/views/app/events/Index"),
          beforeEnter: eventList,
          redirect: "/",
          children: [
            {
              path: "/",
              component: () => import("@/views/app/events/List"),
            },
          ],
        },
        {
          path: "/app/dashboard",
          component: () => import("@/views/app/dashboard/Index"),
        },
        {
          path: "/app/course",
          component: () => import("@/views/app/courses/Index"),
          beforeEnter: courseList,
          children: [
            {
              path: "/",
              name: "course-view",

              component: () => import("@/views/app/courses/List"),
            },
            {
              path: "create-course",
              name: "create-course",
              component: () => import("@/views/app/courses/CreateForm"),
            },
            {
              path: "edit-course/:id",
              name: "edit-course",
              component: () => import("@/views/app/courses/EditForm"),
              props: true,
            },
            {
              path: "edit-scorm/:id",
              name: "edit-scorm",
              beforeEnter: scormData,
              component: () => import("@/views/app/courses/ScormForm"),
              props: true,
            },
            {
              path: ":id/course-item",
              beforeEnter: courseItems,
              component: () => import("@/views/app/courses/ItemList"),
              props: true,
            },
            {
              path: ":id/course-item/create",
              name: "create-course-item",
              beforeEnter: courseItems,
              component: () => import("@/views/app/courses/ItemForm"),
              props: true,
              meta: { kind: MODEL_ACTION.CREATE },
            },
            {
              path: ":id/course-item/:itemId/edit",
              name: "edit-course-item",
              beforeEnter: courseItems,
              component: () => import("@/views/app/courses/ItemForm"),
              props: true,
              meta: { kind: MODEL_ACTION.EDIT },
            },
            {
              path: ":id/course-item/:itemId",
              beforeEnter: courseItemQuestions,
              component: () => import("@/views/app/courses/QuestionList"),
              props: true,
            },
            {
              path: ":id/course-item/:itemId/question/create",
              name: "create-course-item-question",
              beforeEnter: courseItemQuestions,
              component: () => import("@/views/app/courses/QuestionForm"),
              props: true,
              meta: { kind: MODEL_ACTION.CREATE },
            },
            {
              path: ":id/course-item/:itemId/question/:questionId/edit",
              name: "edit-course-item-question",
              beforeEnter: courseItemQuestions,
              component: () => import("@/views/app/courses/QuestionForm"),
              props: true,
              meta: { kind: MODEL_ACTION.EDIT },
            },
          ],
        },
        {
          path: "/app/sign-up-report",
          component: () => import("@/views/app/sign-up-report/Index"),
          children: [
            {
              path: "/",
              component: () => import("@/views/app/sign-up-report/List"),
            },
          ],
        },
        {
          path: "/app/video-input",
          component: () => import("@/views/app/video-inputs/Index"),
          beforeEnter: videoInputs,
          redirect: "/",
          children: [
            {
              path: "/",
              component: () => import("@/views/app/video-inputs/List"),
            },
            {
              path: ":id/edit",
              component: () => import("@/views/app/video-inputs/Form"),
              props: true,
            },
          ],
        },
        {
          path: "/app/student",
          component: () => import("@/views/app/students/Index"),
          children: [
            {
              path: "/",
              component: () => import("@/views/app/students/List"),
            },
            {
              path: "create",
              name: "create-student",
              component: () => import("@/views/app/students/Form"),
              props: { isCreate: true },
            },
            {
              path: "edit/:id",
              name: "edit-student",
              component: () => import("@/views/app/students/Form"),
              props: { isCreate: false },
            },
            {
              path: "form/:id",
              name: "edit-student-course",
              beforeEnter: studentCourseList,
              component: () => import("@/views/app/students/StudentCourseForm"),
              props: { isEdit: true },
            },
          ],
        },
        {
          path: "/app/sign-up",
          component: () => import("@/views/app/sign-up/Index"),
          beforeEnter: signUpList,
          children: [
            {
              path: "/",
              component: () => import("@/views/app/sign-up/List"),
            },
            {
              path: "create",
              name: "create-sign-up",
              component: () => import("@/views/app/sign-up/Form"),
              props: { isCreate: true },
            },
            {
              path: "edit/:id",
              name: "edit-sign-up",
              component: () => import("@/views/app/sign-up/Form"),
              props: { isCreate: false },
            },
          ],
        },
        {
          path: "/app/customer",
          component: () => import("@/views/app/customers/Index"),
          children: [
            {
              path: "/",
              component: () => import("@/views/app/customers/List"),
            },
            {
              path: "form",
              name: "add-customer",
              beforeEnter: customersData,
              component: () => import("@/views/app/customers/Form"),
              props: { isEdit: false },
            },
            {
              path: "form/:id",
              name: "edit-customer",
              beforeEnter: customersData,
              component: () => import("@/views/app/customers/Form"),
              props: { isEdit: true },
            },
            {
              path: "courses/view/:id",
              name: "view-customer-course",
              beforeEnter: customerCourseList,
              component: () =>
                import("@/views/app/customers/CustomerCourseForm"),
            },
            {
              path: ":id/students",
              beforeEnter: customerStudents,
              component: () => import("@/views/app/customers/StudentList"),
            },
            {
              path: ":id/students/create",
              beforeEnter: customerStudentsForm,
              component: () => import("@/views/app/customers/StudentForm"),
            },
            {
              path: ":id/students/import",
              beforeEnter: customersData,
              component: () =>
                import("@/views/app/customers/ImportStudentForm"),
            },
            {
              path: ":id/settings",
              beforeEnter: customersData,
              component: () => import("@/views/app/customers/SettingsForm"),
            },
          ],
        },
        {
          path: "/app/video",
          component: () => import("@/views/app/videos/Index"),
          beforeEnter: videoList,
          redirect: "/",
          children: [
            {
              path: "/",
              component: () => import("@/views/app/videos/List"),
            },
            {
              path: "create",
              component: () => import("@/views/app/videos/Form"),
              meta: { kind: MODEL_ACTION.CREATE },
              props: true,
            },
            {
              path: ":id/edit",
              component: () => import("@/views/app/videos/Form"),
              meta: { kind: MODEL_ACTION.EDIT },
              props: true,
            },
          ],
        },
        {
          path: "/app/progress-report/:id",
          component: () => import("@/views/app/progress-report/Index"),
          redirect: "/",
          children: [
            {
              path: "/",
              component: () => import("@/views/app/progress-report/List"),
            },
          ],
        },
        {
          path: "/app/franchise",
          component: () => import("@/views/app/franchise/Index"),
          children: [
            {
              path: "/",
              component: () => import("@/views/app/franchise/List"),
            },
            {
              path: "create",
              name: "create-franchise",
              component: () => import("@/views/app/franchise/Form"),
              meta: { kind: MODEL_ACTION.CREATE },
              props: true,
            },
            {
              path: ":id/edit",
              component: () => import("@/views/app/franchise/Form"),
              meta: { kind: MODEL_ACTION.EDIT },
              props: true,
            },
            {
              path: ":id/customers",
              component: () => import("@/views/app/franchise/CustomerForm"),
            },
          ],
        },
      ],
    },
    {
      path: "*",
      redirect: "/app/dashboard",
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.path) {
    store.dispatch("changeThemeLoadingState", true);
  }

  next();
});

router.afterEach(() => {
  setTimeout(() => store.dispatch("changeThemeLoadingState", false), 500);
  store.dispatch("changeThemeLoadingState", false);
});

export default router;
